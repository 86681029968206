<template>
    <div >
      <v-form
      ref="detalle"
      v-model="valid"
      lazy-validation
      >
        <!-- nuevos campos -->
        <v-row>
            <v-col cols="12" md="8" >
                <v-autocomplete
                :items="filteredTest"
                v-model="form_data.data_ensayo.ensayoSelect"
                item-text="ensa_desc"
                item-value="grupo_ensayo_id"
                label="ENSAYO"
                :disabled="!form_data.isNew || form_data.nuevoensayo"
                @change="testSelected($event)"
                outlined
                dense
                required
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="!form_data.nuevoensayo" >
                <v-btn
                    color="primary"
                    class="mr-4"
                    :disabled="!form_data.isNew"
                    @click="clearDataForm();form_data.nuevoensayo=true;"
                >
                    agregar nuevo ensayo +
                </v-btn>
            </v-col>
            <v-col cols="12" md="4" v-if="form_data.nuevoensayo" >
                <v-btn
                    color="primary"
                    class="mr-4"
                    :disabled="!form_data.isNew"
                    @click="clearDataForm();form_data.nuevoensayo=false;"
                >
                    Limpiar
                </v-btn>
            </v-col>
        </v-row>
        <!-- cuando la variable nuevo ensayo este activado -->
        <div v-if="form_data.nuevoensayo">
          <v-row>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoMeto==1">
                <v-autocomplete
                    dense
                    :items="data_ensayo.empresasList"
                    v-model="form_data.data_ensayo.empresa"
                    :search-input.sync="search"
                    :disabled="form_data.isComercial"
                    item-text="empresa"
                    item-value="id"
                    label="EMPRESA"
                    chips
                    required
                    persistent-hint
                    @keydown.enter="agregaEmpresa()"
                >
                  <template v-slot:no-data v-if="search">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No se encontro "<strong>{{ search }}</strong>"
                          . Presiona <kbd>enter</kbd> para crear.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoMeto==1">
                <v-autocomplete
                    :items="listProducts"
                    v-model="form_data.data_ensayo.productoid"
                    :search-input.sync="searchProd"
                    item-text="producto"
                    item-value="id"
                    label="PRODUCTO"
                    :disabled="form_data.isComercial"
                    chips
                    required
                    dense
                    persistent-hint
                    @keydown.enter="agregaProducto()"
                >
                  <template v-slot:no-data v-if="searchProd">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No se encontro "<strong>{{ searchProd }}</strong>"
                          . Presiona <kbd>enter</kbd> para crear.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" >
                <v-autocomplete
                    :items="data_ensayo.parametros"
                    v-model="form_data.data_ensayo.parametrosid"
                    :rules="rules.parametros"
                    :search-input.sync="searchParam"
                    :disabled="form_data.isComercial"
                    item-text="nombre"
                    item-value="cod_pat"
                    label="PARÁMETROS"
                    multiple="multiple"
                    required
                    chips
                    dense
                    persistent-hint
                    @keydown.enter="agregaParametro()"
                >
                  <template v-slot:no-data v-if="searchParam">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No se encontro "<strong>{{ searchParam }}</strong>"
                          . Presiona <kbd>enter</kbd> para crear.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <!-- <v-col cols="12" md="12" >
                <v-text-field
                name="nombre"
                label="Nombre"
                v-model="form_data.data_ensayo.nombre"
                :rules="rules.nombre"
                required
                auto-grow
                ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12" >
                <v-text-field
                name="descripcion"
                label="DESCRIPCIÓN"
                v-model="form_data.data_ensayo.descripcion"
                :rules="rules.descripcion"
                required
                auto-grow
                dense
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" >
                <v-text-field
                name="metodologia"
                label="METODOLOGÍA"
                :rules="rules.metodologia"
                v-model="form_data.data_ensayo.metodologia"
                :disabled="form_data.isComercial"
                required
                auto-grow
                dense
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoEnsa!==2">
                <v-text-field
                type="number"
                onkeydown="return event.keyCode !== 69"
                name="enriquecimiento"
                label="ENRIQUECIMIENTO (Hrs)"
                :rules="[tiempoEnrequecimiento]"
                :disabled="form_data.data_ensayo.tipoEnsa===2 || form_data.isComercial"
                v-model="form_data.data_ensayo.tiempoenriquecimiento"
                outlined
                required
                dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" >
                <v-autocomplete
                    :items="tecnicasList"
                    v-model="form_data.data_ensayo.tecnica"
                    :disabled="form_data.isComercial"
                    :rules="rules.tipoTecnica"
                    item-text="nombre"
                    item-value="id"
                    label="TIPO TÉCNICA"
                    outlined
                    required
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field
                type="number"
                name="tiporesultado"
                onkeydown="return event.keyCode !== 69"
                :rules="rules.tiempoResultado"
                :disabled="form_data.isComercial"
                label="TIEMPO RESULTADOS (Días)"
                v-model="form_data.data_ensayo.tiemporesult"
                required
                outlined
                dense
                ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoEnsa===1">
                <v-text-field
                type="number"
                name="nplacas"
                label="Número de placas"
                :rules='[numeroPlacas]'
                v-model="form_data.data_ensayo.numplacas"
                :disabled="form_data.data_ensayo.tipoEnsa!==1"
                outlined
                required
                dense
                ></v-text-field>
            </v-col>
          </v-row> -->

        <!-- por el momento solo para TAAG -->
          <v-row>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoEnsa===1">
                <v-text-field
                type="number"
                name="nplacas"
                label="Número de placas"
                :rules='[numeroPlacas]'
                v-model="form_data.data_ensayo.numplacas"
                :disabled="form_data.data_ensayo.tipoEnsa!==1 || form_data.isComercial"
                outlined
                required
                dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" >
                <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    label="TIPO RESULTADO"
                    :disabled="form_data.steriplex || form_data.isComercial"
                    :rules="rules.tipores"
                    :items="data_ensayo.tipo_resultados"
                    v-model="form_data.data_ensayo.tipoRes"
                    outlined
                    dense
                    required
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" >
                <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    label="CLASIFICACIÓN"
                    :items="arraytipoensayo"
                    v-model="form_data.clasificacion"
                    :disabled="form_data.isComercial"
                    outlined
                    dense
                    required
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="form_data.steriplex === true">
                <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    label="Kit Ensayo Steriplex"
                    :rules="rules.tipokit"
                    :items="kitsStriplex"
                    v-model="form_data.kitsteriplex"
                    outlined
                    dense
                    required
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoEnsa === 4">
              <!-- <p class="font-weight-black">MIXES</p> -->
              <v-autocomplete
              :items="listmix"
              v-model="form_data.data_ensayo.mixes"
              item-text="mixname"
              item-value="id"
              label="MIX"
              required
              outlined
              dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="form_data.data_ensayo.tipoEnsa === 4" >
                <v-btn
                color="primary"
                class="mr-4"
                @click="modalMix()"
                >
                agregar nuevo Mix
                </v-btn>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="4" >
                <p class="font-weight-black">MATRICES Y UNIDADES</p>
                <v-card>
                    <v-card-text>
                        <v-row align="center"
                        v-for="(item) in form_data.matrices_unidades"
                        :key="item.id">
                            <v-checkbox
                            hide-details
                            class="shrink mr-2 mt-0"
                            v-model="item.activo"
                            :disabled="form_data.isComercial"
                            @change="activematriz($event,item)"
                            ></v-checkbox>
                            <v-text-field
                            :label="item.value"
                            :disabled="form_data.isComercial"
                            :value="item.unidades"
                            v-model="item.unidades"
                            />
                        </v-row>

                  </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <newmix ref="modalmixes"></newmix>
        <parameters ref="modalparametros"></parameters>
      </v-form>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Service from '../../services/apis';

export default {
  props: {
    setsee: Function,
  },
  components: {
    newmix: () => import('./newMix.vue'),
    parameters: () => import('./modalParametros.vue'),
  },
  data: () => ({
    valid: false,
    tipoEnsa: null,
    // REGLAS
    rules: {
      producto: [
        (v) => !!v || 'El producto es requerido',
      ],
      parametros: [
        (v) => (v.length > 0) || 'Selecciona al menos un parametro ',
      ],
      tipores: [
        (v) => !!v || 'El tipo resultado es requerido ',
      ],
      tipokit: [
        (v) => !!v || 'El tipo de kit es requerido ',
      ],
      descripcion: [
        (v) => !!v || 'La descripción del ensayo es requerida ',
      ],
      metodologia: [
        (v) => !!v || 'La metodología es requerida ',
      ],
      empresa: [
        (v) => !!v || 'Debes seleccionar una empresa ',
      ],
      tiempoResultado: [
        (v) => !!v || 'Teimpo resultado es requerido ',
      ],
      tipoTecnica: [
        (v) => !!v || 'Tipo técnica es requerido ',
      ],
      nombre: [
        (v) => !!v || 'El nombre del ensayo es requerido ',
      ],
    },
    search: null,
    searchProd: null,
    searchParam: null,
    kitsStriplex: [
      {
        id: 1,
        nombre: 'Steriplex Bacterias',
      },
      {
        id: 2,
        nombre: 'Steriplex hongos y levaduras',
      },
    ],
    arraytipoensayo: [
      {
        id: 1,
        nombre: 'Patógeno',
      },
      {
        id: 2,
        nombre: 'Indicador',
      },
      {
        id: 3,
        nombre: 'Deteriorante',
      },
    ],
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo', 'userInfo']),
    listmix() {
      const mixlizt = this.data_ensayo.mix;
      return mixlizt;
    },
    filteredTest() {
      /*
      const listTest = this.data_ensayo.ensayosList.filter((ensayo) => {
        const details = this.form_data.data_ensayo;
        return (ensayo.tipo_ensayo_id === details.tipoEnsa
         && ensayo.comercial === (details.tipoMeto === 1));
      });
      */
      const ensa = this.data_ensayo.ensayosList;
      const details = this.form_data.data_ensayo;
      const listTest = [];
      ensa.forEach((ensayo) => {
        if (ensayo.comercial === true && ensayo.tipo_ensayo_id === details.tipoEnsa) {
          listTest.push(ensayo);
        }
      });
      return listTest;
    },
    listProducts() {
      const pEmpresas = this.data_ensayo.productosEmpresaslist;
      return pEmpresas.filter((prod) => prod.empresa_id === this.form_data.data_ensayo.empresa);
    },
    tecnicasList() {
      const tecnicasL = this.data_ensayo.tecnicas;
      const dataEnsayoS = this.form_data.data_ensayo;
      return tecnicasL.filter((tecnica) => dataEnsayoS.tipoEnsa === tecnica.tipo_ensayo_id);
    },
  },
  created() {

  },
  methods: {
    ...mapMutations('Ensayos', ['setValidDataEnsayo', 'setFormStep', 'setStep', 'clearAllDataForm', 'datosEnsayo', 'fillObjectForm', 'clearAllDataForm']),
    validate() {
      return this.$refs.detalle.validate();
    },
    tiempoEnrequecimiento(v) {
      const w = this.form_data.data_ensayo.tipoEnsa === 2 || v;
      return !!w || 'Teimpo de enrequecimiento es requerido ';
    },
    numeroPlacas(v) {
      const w = this.form_data.data_ensayo.tipoEnsa !== 1 || v;
      return !!w || 'Número de placas es requerido ';
    },
    activematriz(ev, item) {
      this.form_data.matrices_unidades.forEach((matriz, key) => {
        if (matriz.id === item.id) {
          if (ev === true) {
            // eslint-disable-next-line
            this.form_data.matrices_unidades[key].activo = true;
          } else {
            // eslint-disable-next-line
            this.form_data.matrices_unidades[key].activo = false;
          }
        }
        return true;
      });
    },
    modalMix() {
      this.$refs.modalmixes.openmodalmix();
    },
    clearFirstStep() {
      this.form_data.data_ensayo.tipoEnsa = null;
      this.form_data.data_ensayo.tipoMeto = null;
      this.form_data.data_ensayo.sitioAnalisis = null;
    },
    clearDataForm() {
      this.form_data.isComercial = false;
      this.form_data.data_ensayo.empresa = null;
      this.form_data.data_ensayo.ens_tipo = null;
      this.form_data.data_ensayo.descripcion = null;
      this.form_data.data_ensayo.metodologia = null;
      this.form_data.data_ensayo.numplacas = null;
      this.form_data.data_ensayo.parametrosid = [];
      this.form_data.data_ensayo.tiempoenriquecimiento = null;
      // this.form_data.data_ensayo.nombre = null;
      this.form_data.data_ensayo.tiemporesult = null;
      this.form_data.data_ensayo.tecnica = null;
      this.form_data.data_ensayo.productoid = null;
      // this.form_data.data_ensayo.tipoRes = null;
      this.form_data.data_ensayo_original = null;
      this.form_data.data_ensayo.ensayoSelect = 0;
      this.form_data.isNew = true;
      this.form_data.data_ensayo.mixes = null;
      this.form_data.nuevoensayo = false;

      const umbral = [{ id: 0, umbral: '', rsa_id: [] }];

      this.form_data.ambientesSelect = umbral;
      this.form_data.aguasSelect = umbral;
      this.form_data.manipuldoresSelect = umbral;
      this.form_data.superficiesSelect = umbral;
      this.form_data.alimentosSelect = umbral;

      this.form_data.matrices_unidades.map((currentValue, index) => {
        const curr = currentValue;
        this.form_data.matrices_unidades[index].activo = false;
        this.form_data.matrices_unidades[index].unidades = '';
        return curr;
      });
    },
    async testSelected(id) {
      this.clearFirstStep();
      this.clearDataForm();
      const testToEdit = await Service.apiToken('POST', 'get-test-id', this.$ls.storage.token, { grupo_ensayo_id: id });
      this.form_data.isNew = true;

      if (testToEdit && testToEdit.data) {
        const dataTest = testToEdit.data[0];
        const empresaProducto = dataTest.ensayos_empresas_productos[0] || [];
        const laboratorio = dataTest.ensayos_laboratorios[0] || [];
        const mix = dataTest.ensayos_has_mixs[0] || [];
        const codesPat = dataTest.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.cod_pat);
        // si el ensayo no es del cliente logueado
        if (this.userInfo.user.id_planta !== dataTest.cliente_id) {
          // cambiamos a true para bloquear campos
          this.form_data.isComercial = true;
        }
        this.form_data.data_ensayo.tipoEnsa = dataTest.tipo_ensayo_id;
        this.form_data.data_ensayo.tipoMeto = (dataTest.comercial) ? 1 : 0;
        this.form_data.data_ensayo.empresa = empresaProducto.empresa_id || null;
        // this.form_data.data_ensayo.nombre = dataTest.ensa_informe;
        this.form_data.data_ensayo.nombre = dataTest.ensayos_easypcrs[0].name_ensayo;
        this.form_data.data_ensayo.ens_tipo = dataTest.d_tipo_de_ensayo;
        // this.form_data.data_ensayo.descripcion = dataTest.ensa_desc;
        this.form_data.data_ensayo.descripcion = dataTest.ensayos_easypcrs[0].descripcion;
        this.form_data.data_ensayo.metodologia = dataTest.metodologia;
        this.form_data.data_ensayo.numplacas = dataTest.numero_placas;
        this.form_data.data_ensayo.parametrosid = codesPat;
        this.form_data.data_ensayo.tiempoenriquecimiento = dataTest.tiempo_incubacion;
        this.form_data.data_ensayo.tiemporesult = dataTest.dias;
        this.form_data.data_ensayo.tecnica = dataTest.tecnicas_id;
        this.form_data.data_ensayo.productoid = empresaProducto.producto_empresa_id || null;
        this.form_data.data_ensayo.tipoRes = dataTest.t_resultado;
        this.form_data.data_ensayo.sitioAnalisis = laboratorio.laboratorio_id || null;
        this.form_data.data_ensayo_original = dataTest.grupo_ensayo_id;
        this.form_data.data_ensayo.mixes = mix.mixs_id || null;
        this.form_data.nuevoensayo = true;
        this.form_data.clasificacion = dataTest.clasificacion;
        this.form_data.steriplex = (dataTest.steriplex === 1);
        this.form_data.kitsteriplex = dataTest.kit_steriplex;

        testToEdit.data.forEach((test) => {
          const matrixObject = this.form_data.matrices_unidades;
          const matrixFound = matrixObject.findIndex((element) => element.id === test.matrices_id);
          this.form_data.matrices_unidades[matrixFound].activo = true;
          this.form_data.matrices_unidades[matrixFound].unidades = test.unidades;

          const groups = _.groupBy(test.umbrales, 'umbral');
          let umbral = [];
          const arrayMatriz = [12, 9, 7];
          Object.keys(groups).map((group, index) => {
            // if (groups[group][0].matriz_id === 12) {
            if (arrayMatriz.includes(groups[group][0].matriz_id)) {
              umbral.push({
                id: index,
                umbral: groups[group][0].umbral,
                unidades: groups[group][0].unidades,
                rsa_id: groups[group].map((umb) => (umb.subrsa_id === 0 ? `${umb.rsa_id}_R` : `${umb.subrsa_id}_S_${umb.rsa_id}`)),
              });
            } else {
              umbral.push({
                id: index,
                umbral: groups[group][0].umbral,
                rsa_id: groups[group].map((umb) => umb.rsa_id),
                unidades: groups[group][0].unidades,
              });
            }
            return true;
          });

          const noUmbral = [{ id: 0, umbral: '', rsa_id: [] }];
          umbral = (umbral.length > 0) ? umbral : noUmbral;

          switch (test.matrices_id) {
            case 6: this.form_data.data_umbrales.ambientesSelect = umbral; break;
            case 7: this.form_data.data_umbrales.aguasSelect = umbral; break;
            case 8: this.form_data.data_umbrales.manipuldoresSelect = umbral; break;
            case 9: this.form_data.data_umbrales.superficiesSelect = umbral; break;
            case 12: this.form_data.data_umbrales.alimentosSelect = umbral; break;
            default: break;
          }
          return true;
        });
      }
    },
    async agregaEmpresa() {
      // si search es diferente de null vamos a enviar a guardarlo
      if (this.search != null) {
        const crearE = {
          nombre: this.search,
          cliente_id: 1,
        };
        const creaEmpresa = await Service.apiToken('POST', 'crea-empresa', this.$ls.storage.token, crearE);
        // hacemos push en el objeto que se esta terando
        this.data_ensayo.empresasList.push(creaEmpresa.data);
      }
    },
    async agregaProducto() {
      if (this.searchProd != null) {
        const crearProd = {
          nombre: this.searchProd,
          cliente_id: 1,
          empresa_id: this.form_data.data_ensayo.empresa,
        };
        const creaproducto = await Service.apiToken('POST', 'crea-producto', this.$ls.storage.token, crearProd);
        this.data_ensayo.productosEmpresaslist.push(creaproducto.data);
      }
    },
    async agregaParametro() {
      if (this.searchParam != null) {
        const dateact = new Date();
        const seconds = dateact.getTime();
        const crearParam = {
          nombre: this.searchParam,
          nomcort: `${this.searchParam}_${seconds}`,
          clasificacion: 2,
          especie: null,
          unidad: null,
          color: '#795548',
          nombre_es: this.searchParam,
        };
        const creaparam = await Service.apiToken('POST', 'crea-parametro', this.$ls.storage.token, crearParam);
        this.data_ensayo.parametros.push(creaparam.data);
      }
    },
    openmodal() {
      this.$refs.modalparametros.openmodal();
    },
  },
};
</script>
